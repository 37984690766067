//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {}
  },
  props: ['isPicture', 'books'],
  methods: {
    close() {
      this.$emit('closeBooks')
    },
  },
}
