/*
 * @Author: 沈怀志 8385055+shen-huaizhi@user.noreply.gitee.com
 * @Date: 2022-05-17 16:08:51
 * @LastEditors: 沈怀志 8385055+shen-huaizhi@user.noreply.gitee.com
 * @LastEditTime: 2022-05-17 16:21:57
 * @FilePath: \ananyuweiback\src\api\home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { request, requestFd } from '../core/request'

// 首页接口
export function _getHomepPage(params) {
  console.log('首页接口')
  return request({
      url:'/ananops/api/v1/pri/account/homepage',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: false // 是否加token
  })
}
// 证书模糊查询
export function _getselectStarCompanys(params) {
  console.log('证书模糊查询')
  return request({
      url:'/ananops/api/v1/pri/account/selectStarCompanys',
      method: 'get',
      params: params,
      showLoading: 'true',
      tokenStatus: false // 是否加token
  })
}
// 证书精准查询：
export function _getselectStarCompany(params) {
  console.log('证书精准查询')
  return request({
      url:'/ananops/api/v1/pri/account/selectStarCompany',
      method: 'get',
      params: params,
      showLoading: 'true',
      tokenStatus: false // 是否加token
  })
}