//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  _getHomepPage,
  _getselectStarCompanys,
  _getselectStarCompany,
} from '@/api/home'
import BookTips from './BooksTips.vue'
export default {
  data() {
    let _this = this
    return {
      // 图片数据
      picList: [
        { pic_src: require('../../../assets/homeimg/image_image_sx@1x-1.png') },
        { pic_src: require('../../../assets/homeimg/image_image_sx@1x-1.png') },
        { pic_src: require('../../../assets/homeimg/image_image_sx@1x-1.png') },
        { pic_src: require('../../../assets/homeimg/image_image_sx@1x-1.png') },
        { pic_src: require('../../../assets/homeimg/image_image_sx@1x-1.png') },
      ],
      input: '',
      applist: {
        demandCcompanySum: null, //需求方企业总数
        providerCompanySum: null, //服务商企业总数
        reportSum: null, //平台报障工单总数
        maintenanceReportSum: null, //平台维保工单总数
      },
      servicelist: [],
      books: {
        deptName: '', //公司名称
        opComment: '', //编号
        level: null, //星级
        auditTime: '', //颁证日期
        validTime: '',
        startTime:'',
        endTime:'',
      },
      isBooks: false, //控制弹窗
      isPicture: 3, //控制证书处于什么状态   3默认  1 无证书 2 有
      // Swiper 配置项
      swiperOption: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 3, // Swiper的列数
        // centeredSlides: true,
        clickable: true,
        spaceBetween: 30, //板块间距
        slidesPerGroup: 3,
        loopFillGroupWithBlank: true,

        // 设置分页器
        // pagination: {
        //   el: ".swiper-pagination",
        //   type: 'bullets',//将分页的类型改为圆点（默认)
        //   clickable: true,// 点击可切换
        //   // hideOnClick :true, // 点击swiper-slide显隐
        // },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 2秒切换一次
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },

        loop: true, //无缝轮播
        grabCursor: true, // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      },
      options: [],
      companyName: '',
      loading: false,
    }
  },
  components: {
    BookTips,
  },
  created() {
    _getHomepPage({}).then((res) => {
      console.log(res.data)
      let {
        demandCcompanySum,
        providerCompanySum,
        reportSum,
        maintenanceReportSum,
        starProviderCompany,
      } = res.data
      let form = {
        demandCcompanySum,
        providerCompanySum,
        reportSum,
        maintenanceReportSum,
      }
      this.applist = form
      this.servicelist = starProviderCompany
    })
  },
  methods: {
    showBooks() {
      this.isBooks = true
    },
    closeBooks() {
      this.isBooks = false
    },
    moreBtn() {
      // this.$router.push('/homes/login')
      console.log(this.$route.query.login)
      if (this.$route.query.login) {
        let roleType = JSON.parse(localStorage.getItem('roleType'))
        if (roleType == '1') {
          this.$router.replace('/main/ptstarrating/starratinlist?star=true')
        } else if (roleType.indexOf('7') != -1) {
          this.$router.replace('/main/starrating/starratins?star=true')
        } else if (
          roleType.indexOf('2') != -1 ||
          roleType.indexOf('3') != -1 ||
          roleType.indexOf('4') != -1
        ) {
          this.$message.error('该功能暂未对需求方开放')
        } else {
          this.$message.error('请联系管理员进行操作')
        }

        // sessionStorage.setItem('active', JSON.stringify("starrating"))

        // /main/ptstarrating/starratinlist  roleType
      } else {
        this.$router.replace('/homes/login')
      }
    },
    tofws() {
      this.$router.replace('/homes/register?type=2')
    },
    toxqf() {
      this.$router.replace('/homes/register?type=1')
    },
    // 鼠标覆盖停止自动切换,鼠标离开开始自动切换
    handleAuto(status) {
      //如果你初始化时没有定义Swiper实例，后面也可以通过Swiper的HTML元素来获取该实例
      let mysSwiper = document.querySelector('.my_swiper').swiper
      // status?(mysSwiper.autoplay.stop()):(mysSwiper.autoplay.start());
    },
    // 鼠标点击页面
    handleSwiper(event) {
      let dataset = event.target.dataset
      console.log(dataset)
      console.log(dataset.path, dataset.yaya) // 自定义，可获取相应的值
      // 跳转
      // window.location.href = "https://www.baidu.com"
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        _getselectStarCompanys({ companyName: query }).then((res) => {
          this.loading = false
          this.options = res.data
          console.log(res.data)
        })
      } else {
        this.options = []
      }
    },
    changeCompanyName() {
      console.log(this.companyName, this.options)
      let form = this.options.filter((item) => {
        return this.companyName == item.deptName
      })
      this.books = {
        opComment: form[0].opComment,
        deptName: form[0].deptName,
        level: form[0].level,
        auditTime: form[0].auditTime,
        validTime: form[0].validTime,
        startTime: form[0].startTime,
        endTime: form[0].endTime,
      }
      if (form[0].level) {
        this.isPicture = 2
      } else {
        this.isPicture = 1
      }
      // console.log(this.books)
    },
  },
}
